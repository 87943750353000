import logo from "./logo.svg";
import "./App.css";
import Invoice from "./Invoice";
import Invoices from "./Invoices";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import InvoiceViewer from "./invoice-viewer";

function App() {
  return (
    <Router>
      <div>
        <nav>
          <ul>
            <li>
              <Link to="/create">Create</Link>
            </li>
            <li>
              <Link to="/invoices">Invoices</Link>
            </li>
          </ul>
        </nav>
        <Routes>
          <Route exact path="/create" element={<Invoice/>} />
          <Route path="/invoices" element={<Invoices/>} />
          <Route path="/invoice/:invoiceNumber" element={<InvoiceViewer/>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
