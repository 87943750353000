import React from "react";
import { Formik, Field, Form, FieldArray, useFormikContext } from "formik";
import * as Yup from "yup";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // Import useNavigate for React Router v6

import "./Invoice.css";

const InvoiceSchema = Yup.object().shape({
  client: Yup.string().required("Required"),
  items: Yup.array().of(
    Yup.object().shape({
      description: Yup.string().required("Required"),
      quantity: Yup.number().required("Required").positive("Must be positive"),
      price: Yup.number().required("Required").positive("Must be positive"),
    })
  ),
});

const InvoicePreview = () => {
  const { values } = useFormikContext();

  return (
    <div className="invoice-preview" id="invoice-preview">
      <div className="invoice">
        <div className="invoice-header">
          <img src="/header.png" alt="Header" />
        </div>
        <div className="invoice-body">
          <table className="contact-table">
            <thead>
              <tr>
                <th className="contact-table-no-border">Client</th>
                <th className="contact-table-no-border contact-table-align-right">
                  Invoice #
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="contact-table-no-border">{values.client}</td>
                <td className="contact-table-no-border contact-table-align-right">
                  {Math.floor(Math.random() * 100000)}
                </td>
              </tr>
              <tr>
                <td className="contact-table-no-border"></td>
                <td className="contact-table-no-border"></td>
              </tr>
              <tr>
                <td className="contact-table-no-border">{values.address1}</td>
                <td className="contact-table-no-border contact-table-align-right">
                  <strong>Date:</strong> {new Date().toLocaleString()}
                </td>
              </tr>
              <tr>
                <td className="contact-table-no-border">{values.address2}</td>
                <td className="contact-table-no-border"></td>
              </tr>
              <tr>
                <td className="contact-table-no-border">{values.address3}</td>
                <td className="contact-table-no-border"></td>
              </tr>
            </tbody>
          </table>
          <h1>INVOICE</h1>
          <table>
            <thead>
              <tr>
                <th>DESCRIPTION</th>
                <th>QUANTITY</th>
                <th>PRICE</th>
                <th>AMOUNT</th>
              </tr>
            </thead>
            <tbody>
              {values.items.map((item, index) => (
                <tr key={index}>
                  <td>{item.description}</td>
                  <td className="align-right">{item.quantity.toFixed(2)}</td>
                  <td className="align-right">{item.price.toFixed(2)}</td>
                  <td className="align-right">
                    {(item.quantity * item.price).toFixed(2)}
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan={3} style={{ textAlign: "right" }}>
                  <strong>TOTAL</strong>
                </td>
                <td className="align-right">
                  <strong>
                    {values.items
                      .reduce(
                        (sum, item) => sum + item.quantity * item.price,
                        0
                      )
                      .toFixed(2)}
                  </strong>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="notes-section">
            <strong>Notes</strong>
            <div className="box-with-radius">
              <div className="box-with-radius-content">
                <p>{values.notes}</p>
              </div>
            </div>
          </div>
          <div className="terms-and-conditions-section">
            <strong>Terms & Conditions</strong>
            <div className="box-with-radius">
              <div className="box-with-radius-content">
                <p>{values.termsAndConditions}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="invoice-footer">
          <img src="/footer.png" alt="Footer" />
        </div>
      </div>
    </div>
  );
};

const Invoice = () => {
  const navigate = useNavigate(); // Use useNavigate hook

  const printDocument = () => {
    const input = document.getElementById("invoice-preview");
    html2canvas(input, { scale: 2, scrollY: -window.scrollY }).then(
      (canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("invoice.pdf");
      }
    );
  };

  return (
    <div className="invoice-container">
      <Formik
        initialValues={{
          client: "",
          address1: "",
          address2: "",
          address3: "",
          notes: "",
          termsAndConditions: "",
          items: [
            {
              description: "",
              quantity: 0,
              price: 0,
            },
          ],
        }}
        validationSchema={InvoiceSchema}
        onSubmit={(values) => {
          axios.post('https://invoice.teachmeit.lk/api/invoice', values)
            .then(response => {
              console.log('Invoice saved successfully:', response.data);
              navigate(`/invoice/${response.data.invoice_number}`); // Navigate to the invoice viewer page
            })
            .catch(error => {
              console.error('Error saving invoice:', error);
            });
        }}
      >
        {({ values, resetForm }) => (
          <>
            <div className="form-container">
              <div className="invoice-form">
                <div className="invoice-form-content">
                  <Form>
                    <div className="form-group">
                      <label>Client</label>
                      <Field
                        name="client"
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group">
                      <label>Address 1</label>
                      <Field
                        name="address1"
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group">
                      <label>Address 2</label>
                      <Field
                        name="address2"
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group">
                      <label>Address 3</label>
                      <Field
                        name="address3"
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group">
                      <label>Notes</label>
                      <Field
                        name="notes"
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group">
                      <label>Terms & Conditions</label>
                      <Field
                        name="termsAndConditions"
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <FieldArray name="items">
                      {({ insert, remove, push }) => (
                        <div>
                          <table className="item-table">
                            <thead>
                              <tr>
                                <th>Description</th>
                                <th>Quantity</th>
                                <th>Price</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {values.items.length > 0 &&
                                values.items.map((item, index) => (
                                  <tr key={index}>
                                    <td>
                                      <Field
                                        name={`items.${index}.description`}
                                        placeholder="Description"
                                        type="text"
                                        className="form-control"
                                      />
                                    </td>
                                    <td>
                                      <Field
                                        name={`items.${index}.quantity`}
                                        placeholder="Quantity"
                                        type="number"
                                        className="form-control"
                                      />
                                    </td>
                                    <td>
                                      <Field
                                        name={`items.${index}.price`}
                                        placeholder="Price"
                                        type="number"
                                        className="form-control"
                                      />
                                    </td>
                                    <td>
                                      <button
                                        type="button"
                                        className="secondary"
                                        onClick={() => remove(index)}
                                      >
                                        X
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              <tr>
                                <td colSpan="4">
                                  <button
                                    type="button"
                                    className="secondary add-item-button"
                                    onClick={() =>
                                      push({
                                        description: "",
                                        quantity: 0,
                                        price: 0,
                                      })
                                    }
                                  >
                                    Add Item
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <button type="submit" className="submit-button">
                            Submit
                          </button>
                        </div>
                      )}
                    </FieldArray>
                  </Form>
                </div>
              </div>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};

export default Invoice;
