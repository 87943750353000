import React, { useState, useEffect } from 'react';
import axios from 'axios';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { useParams } from 'react-router-dom';

const InvoiceViewer = () => {
  const { invoiceNumber } = useParams(); // Extract invoiceNumber from URL params
  const [invoice, setInvoice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchInvoice = async () => {
      try {
        const response = await axios.get(`https://invoice.teachmeit.lk/api/invoice/${invoiceNumber}`);
        setInvoice(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchInvoice();
  }, [invoiceNumber]);

  const handleDownloadPDF = () => {
    if (!invoice) return;

    const itemsArray = JSON.parse(invoice.items); // Parse items from string to array

    const input = document.getElementById(`invoice-preview-${invoice.invoice_number}`);
    html2canvas(input, { scale: 2, scrollY: -window.scrollY }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save(`invoice-${invoice.invoice_number}.pdf`);
    });
  };

  if (loading) return <p>Loading invoice...</p>;
  if (error) return <p>Error loading invoice: {error}</p>;
  if (!invoice) return <p>Invoice not found</p>;

  // Calculate total amount based on items array
  const itemsArray = JSON.parse(invoice.items);
  const totalAmount = itemsArray.reduce((total, item) => total + item.quantity * item.price, 0);

  return (
    <div>
      <h1>Invoice Details</h1>
      <div id={`invoice-preview-${invoice.invoice_number}`} className="invoice-preview">
        {/* Render invoice details here */}
        <div className="invoice">
          <div className="invoice-header">
            <img src="/header.png" alt="Header" />
          </div>
          <div className="invoice-body">
            <table className="contact-table">
              <thead>
                <tr>
                  <th className="contact-table-no-border">Client</th>
                  <th className="contact-table-no-border contact-table-align-right">Invoice #</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="contact-table-no-border">{invoice.client}</td>
                  <td className="contact-table-no-border contact-table-align-right">{invoice.invoice_number}</td>
                </tr>
                <tr>
                  <td className="contact-table-no-border"></td>
                  <td className="contact-table-no-border"></td>
                </tr>
                <tr>
                  <td className="contact-table-no-border">{invoice.address1}</td>
                  <td className="contact-table-no-border contact-table-align-right"><strong>Date:</strong> {new Date().toLocaleDateString()}</td>
                </tr>
                <tr>
                  <td className="contact-table-no-border">{invoice.address2}</td>
                  <td className="contact-table-no-border"></td>
                </tr>
                <tr>
                  <td className="contact-table-no-border">{invoice.address3}</td>
                  <td className="contact-table-no-border"></td>
                </tr>
              </tbody>
            </table>
            <h1>INVOICE</h1>
            <table>
              <thead>
                <tr>
                  <th>DESCRIPTION</th>
                  <th>QUANTITY</th>
                  <th>PRICE</th>
                  <th>AMOUNT</th>
                </tr>
              </thead>
              <tbody>
                {itemsArray.map((item, index) => (
                  <tr key={index}>
                    <td>{item.description}</td>
                    <td className="align-right">{item.quantity.toFixed(2)}</td>
                    <td className="align-right">{item.price.toFixed(2)}</td>
                    <td className="align-right">{(item.quantity * item.price).toFixed(2)}</td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={3} style={{ textAlign: 'right' }}><strong>TOTAL</strong></td>
                  <td className="align-right"><strong>{totalAmount.toFixed(2)}</strong></td>
                </tr>
              </tbody>
            </table>
            <div className="notes-section">
              <strong>Notes</strong>
              <div className="box-with-radius">
                <div className="box-with-radius-content">
                  <p>{invoice.notes}</p>
                </div>
              </div>
            </div>
            <div className="terms-and-conditions-section">
              <strong>Terms & Conditions</strong>
              <div className="box-with-radius">
                <div className="box-with-radius-content">
                  <p>{invoice.termsAndConditions}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="invoice-footer">
            <img src="/footer.png" alt="Footer" />
          </div>
        </div>
      </div>
      <button className="download-button" onClick={handleDownloadPDF}>Download PDF</button>
    </div>
  );
};

export default InvoiceViewer;
